import styled from "styled-components";

const SkipLink = styled.a`
  clip: rect(0 0 0 0);
  height: 4px;
  width: 4px;
  margin: -4px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  top: -9999px;

  &:focus {
    padding: 16px;
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    margin: 8px;
    font-weight: bold;
    color: var(--color);
    background-color: var(--bg);
    width: auto;
    height: auto;
    clip: auto;
  }
`;

export default SkipLink;
