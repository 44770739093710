import React from "react";
import { useTranslation } from "../../../src/hooks/useTranslation";

// eslint-disable-next-line react/prop-types
const TranslationExample = () => {
  const translation = useTranslation();
  return (
    <figure style={{ display: "inline-block", margin: "16px" }}>
      <figcaption style={{ textAlign: "left", fontSize: "18px" }}>
        How <strong>Hello World</strong> sounds in Greek:
      </figcaption>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption, react/prop-types */}
      <audio controls src={translation}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </figure>
  );
};

export default TranslationExample;
