import React from "react";
import styled from "styled-components";

import { Box as BaseBox, Centered, Text } from "./Primitives";
import Link from "./Link";
import LinkExternal from "./LinkExternal";

const Box = styled(BaseBox)`
  font-family: var(--font-family-ui);
  font-variation-settings: "wght" var(--regular);
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  text-align: left;
  margin-bottom: calc(var(--rhythm) / 4);
  padding-left: 0;

  li {
    margin-left: calc(var(--rhythm) / 2);
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: calc(var(--rhythm) / 2);

    li {
      margin-left: 0;
    }

    a {
      min-height: 48px;
    }
  }
`;

const Footer = () => (
  <Box
    as="footer"
    bg="var(--footer-bg)"
    color="white"
    borderTop="3px solid var(--bg-dark)"
    role="contentinfo"
  >
    <Centered py={5} px={3}>
      <List>
        <li>
          <Link variant="emerald" to="/about/">
            <span role="img" aria-label="book">
              📗
            </span>
            <span> About</span>
          </Link>
        </li>
        <li>
          <LinkExternal
            variant="emerald"
            href="https://github.com/MarkosKon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span role="img" aria-label="fire">
              🔥
            </span>
            <span> GitHub</span>
          </LinkExternal>
        </li>
        <li>
          <LinkExternal
            variant="emerald"
            href="https://twitter.com/markos_kon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span role="img" aria-label="twitter">
              🐦
            </span>
            <span> Twitter</span>
          </LinkExternal>
        </li>
        <li>
          <LinkExternal
            variant="emerald"
            href="/rss.xml"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span role="img" aria-label="rss">
              📰
            </span>
            <span> rss</span>
          </LinkExternal>
        </li>
      </List>
      <Text fontSize={1} textAlign="center" fontWeight={500}>
        Built with{" "}
        <LinkExternal
          fontSize={2}
          fontWeight={500}
          href="https://www.gatsbyjs.com/"
        >
          Gatsby
        </LinkExternal>
        . Icons made by{" "}
        <LinkExternal
          fontSize={2}
          fontWeight={500}
          href="https://www.freepik.com/"
        >
          Freepik
        </LinkExternal>{" "}
        from{" "}
        <LinkExternal
          fontSize={2}
          fontWeight={500}
          href="https://www.flaticon.com/"
        >
          www.flaticon.com
        </LinkExternal>{" "}
        is licensed by{" "}
        <LinkExternal
          fontSize={2}
          fontWeight={500}
          href="https://creativecommons.org/licenses/by/3.0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CC 3.0 BY
        </LinkExternal>
      </Text>
    </Centered>
  </Box>
);

export default Footer;
