import FontPlayground from "../../../../src/components/FontPlayground";
import TableWrapper from "../../../../src/components/TableWrapper";
import InterestingCharacters from "../../../../src/components/InterestingCharacters";
import WorkSansFullSets from "../../../../content/posts/work-sans-typeface-notes/full-sets.mdx";
import WorkSansIncompleteSets from "../../../../content/posts/work-sans-typeface-notes/incomplete-sets.mdx";
import WorkSansFeatures from "../../../../content/posts/work-sans-typeface-notes/WorkSansFeatures";
import WorkSansFontFace from "../../../../content/posts/work-sans-typeface-notes/WorkSansFontFace";
import * as React from 'react';
export default {
  FontPlayground,
  TableWrapper,
  InterestingCharacters,
  WorkSansFullSets,
  WorkSansIncompleteSets,
  WorkSansFeatures,
  WorkSansFontFace,
  React
};