/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import styled from "styled-components";
import Disclosure from "./DisclosureNative";
import ContentEditable from "./ContentEditable";

const headingWithLink =
  (Tag) =>
  ({ children, id, ...rest }) =>
    (
      <Tag id={id} {...rest}>
        <a className="heading-link" href={`#${id}`}>
          {children}
        </a>
      </Tag>
    );

const Button = styled.button`
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  border: none;
  outline: 1px solid transparent;
  padding: 0.2em 0.5em;
  background-color: transparent;
  color: white;
  font-family: var(--font-family-ui);
  line-height: normal;
  font-size: 0.8em;
  font-variation-settings: "wght" var(--light);
  font-weight: 300;
  transition: background-color 150ms ease-out;

  :hover {
    background-color: var(--grvsc-line-highlighted-background-color);
  }

  :disabled {
    cursor: not-allowed;
  }

  :focus {
    box-shadow: 0 0 0 2px var(--accent);
  }

  :focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const PreWithCopyToClipboard = ({ children, ...props }) => {
  const preRef = useRef();
  return (
    <div className="pre-container">
      <pre {...props} style={{ position: "static" }} ref={preRef}>
        {children}
        <Button
          type="button"
          onClick={() => {
            // use `node.querySelector` for something
            // more robust (and slower)?
            const text = preRef.current.children[0].textContent;

            const successHandler = () => {
              const button = preRef.current.children[1];
              button.textContent = "Copied";
              button.disabled = "true";
              window.setTimeout(() => {
                if (preRef.current) {
                  button.textContent = "Copy";
                  button.disabled = null;
                }
              }, 5000);
            };

            const fallback = () => {
              const textArea = document.createElement("textarea");
              textArea.value = text;
              document.body.append(textArea);
              textArea.select();
              try {
                document.execCommand("copy");
                successHandler();
              } catch (error) {
                // console.error(`Error writing to clipboard: ${error}`);
              }
              document.body.removeChild(textArea);
            };

            if (!navigator.clipboard) {
              fallback();
            } else {
              navigator.clipboard
                .writeText(text)
                .then(() => {
                  successHandler();
                })
                .catch((/* error */) => {
                  // console.error(`Error writing to clipboard: ${error}`);
                });
            }
          }}
        >
          Copy
        </Button>
      </pre>
    </div>
  );
};

const components = {
  h2: headingWithLink("h2"),
  h3: headingWithLink("h3"),
  h4: headingWithLink("h4"),
  h5: headingWithLink("h5"),
  h6: headingWithLink("h6"),
  pre: PreWithCopyToClipboard,
  Disclosure,
  ContentEditable,
};

export default components;
