import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query siteMetadataQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      logo: file(relativePath: { eq: "coding.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 60
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title, description, siteUrl },
    },
    logo,
  } = data;

  return {
    title,
    description,
    siteUrl,
    logo,
  };
};
