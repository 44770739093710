import styled from "styled-components";
import { DesktopList, MobileList } from "accessible-navbar";

import { Centered } from "../Primitives";

const SiteHeading = styled.h1`
  font-family: inherit;
  font-weight: normal;
  font-size: inherit;
  margin: 0;
`;

// Navbars
const CustomDesktopList = styled(DesktopList)`
  position: relative;
  font-family: var(--font-family-ui);
  border-bottom: 4px solid var(--bg-border);
  font-variation-settings: "wght" var(--regular);
  font-size: 16px;

  a {
    font-size: 27px;
  }

  a:active {
    box-shadow: none;
  }

  a:focus:not(:focus-visible) {
    box-shadow: none;
  }
`;

const CustomMobileList = styled(MobileList)`
  font-family: var(--font-family-ui);
  font-variation-settings: "wght" var(--regular);

  a {
    font-size: 47px;
  }

  a:active {
    box-shadow: none;
  }
`;

// Brand styles
const BrandContainer = styled(Centered)`
  flex-direction: row;

  h1,
  a {
    font-variation-settings: "wght" var(--light);
    line-height: 1.1;
  }

  a {
    margin-left: 20px;
    font-size: 36px;
    color: var(--color);
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 34px;
    }
  }
  @media screen and (max-width: 345px) {
    a {
      font-size: 28px;
    }
  }
`;

export {
  SiteHeading,
  CustomDesktopList,
  CustomMobileList,
  BrandContainer,
};
