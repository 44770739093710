import React, { useState, useEffect, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import groupBy from "lodash.groupby";
import {
  includes,
  equals,
  initializeOperations,
} from "compare-object-field";

import { string } from "prop-types";
import { generateFilters } from "./generate-filters";
import { Button as BaseButton } from "../Primitives";
import SkipLink from "../SkipLink";

const operations = {
  EQUALS: equals,
  INCLUDES: includes,
};
const addFilters = initializeOperations(operations);
const allFiltersTrue = true;

const ascendingSort = (field) => (a, z) => {
  if (a[field] < z[field]) return -1;
  if (a[field] > z[field]) return 1;
  return 0;
};
const byValueAsc = ascendingSort("value");

const OuterList = styled.ul`
  list-style: none;
  margin-left: 0 !important;

  & > li {
    margin-bottom: calc(var(--rhythm) / 2) !important;
    margin-left: 0 !important;
  }
`;
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-left: 0 !important;

  li {
    margin: 0 calc(var(--rhythm) / 4) calc(var(--rhythm) / 4) 0 !important;
  }

  strong {
    margin-right: calc(var(--rhythm) / 4);
  }
`;

const Button = styled(BaseButton)`
  margin-bottom: var(--rhythm);
  @media screen and (max-width: 740px) {
    display: none;
  }
`;

const FilterButton = styled.button`
  cursor: pointer;
  border-radius: 0.2em;
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  padding: 0.2em 0.8em;
  border: 1px solid var(--input-border);
  outline: 1px solid transparent;
  transition: background-color 150ms ease-out;
  font-feature-settings: "kern", "liga", "clig", "calt", "lnum", "tnum";

  &[aria-pressed="true"] {
    box-shadow: inset 0 0 4px 2px var(--input-border);
  }

  &:disabled {
    background-color: ${({ bg }) => bg};
    filter: opacity(50%);
  }

  &:hover {
    background-color: ${({ bgHover }) => bgHover};
  }

  &:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px var(--accent);
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
    border: 1px solid var(--input-border);
  }
`;

FilterButton.propTypes = {
  color: string,
  bg: string,
  bgHover: string,
};

FilterButton.defaultProps = {
  color: "var(--color)",
  bg: "var(--inline-code)",
  bgHover: "var(--inline-code-hover)",
};

const TableWrapper = styled.div`
  &.full-width {
    --out-of-the-box: calc(100vw - calc(var(--rhythm) * 24));

    box-shadow: 2px 2px 12px 2px var(--input-border);
    margin-left: calc(-1 * var(--out-of-the-box) / 2);
    margin-right: calc(-1 * var(--out-of-the-box) / 2);
    width: auto;

    &:focus {
      outline: 1px solid transparent;
      box-shadow: 0 0 0 1px var(--muted),
        2px 2px 24px 2px var(--input-border);
    }

    @media screen and (max-width: 740px) {
      --out-of-the-box: var(--rhythm);
    }
  }

  overflow-x: auto;
  margin-bottom: var(--rhythm);
  margin-left: calc(-1 * var(--out-of-the-box));
  margin-right: calc(-1 * var(--out-of-the-box));

  &:focus {
    outline: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--muted);
  }
`;

const Table = styled.table`
  width: 100%;
  background-color: var(--bg-content);

  tbody tr:nth-child(even) td {
    background-color: var(--inline-code-soft);
  }
`;

const SystemFonts = () => {
  const {
    allSystemFontsDataJson: { nodes: fonts },
  } = useStaticQuery(graphql`
    query SystemFonts {
      allSystemFontsDataJson {
        nodes {
          name
          classificationGeneral
          classificationSpecific
          weightsNstyles
          operatingSystems
          notes
        }
      }
    }
  `);
  const [filters] = useState(() =>
    generateFilters(fonts).sort(byValueAsc)
  );
  const [availableFilters, setAvailableFilters] = useState(filters);
  const [groupedFilters] = useState(() => groupBy(filters, "field"));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeFonts, setActiveFonts] = useState(fonts);
  const [fullWidthTable, setFullWidthTable] = useState(false);

  useEffect(() => {
    const satisfiesFilters = addFilters(selectedFilters, allFiltersTrue);
    const newFontList = fonts.filter(satisfiesFilters);
    const newFilterList = generateFilters(newFontList);

    setActiveFonts(newFontList);
    setAvailableFilters(newFilterList);
  }, [selectedFilters, fonts]);

  /* eslint-disable react/prop-types */
  const Filter = useCallback(
    ({ filter }) => {
      const filterAlreadySelected = selectedFilters.find(
        (sf) => filter.id === sf.id
      );
      const filterAvailable = availableFilters.find(
        (af) => af.id === filter.id
      );
      return (
        <li>
          <FilterButton
            type="button"
            disabled={!filterAvailable ? "disabled" : null}
            aria-label={`Add ${filter.value} filter`}
            aria-pressed={filterAlreadySelected ? "true" : "false"}
            onClick={() => {
              if (!filterAlreadySelected)
                setSelectedFilters([...selectedFilters, filter]);
              else {
                const newFilters = selectedFilters.filter(
                  (selectedFilter) => filter.id !== selectedFilter.id
                );
                setSelectedFilters(newFilters);
              }
            }}
          >
            {filter.value}
          </FilterButton>
        </li>
      );
    },
    [selectedFilters, availableFilters]
  );
  /* eslint-enable react/prop-types */

  return (
    <div>
      <SkipLink href="#system-fonts">Skip controls</SkipLink>
      <h3 id="system-fonts-filters">Filters</h3>

      <OuterList>
        <li>
          <List aria-label="Type filters">
            <strong aria-hidden="true">Type: </strong>
            {groupedFilters.classificationGeneral.map((filter) => (
              <Filter filter={filter} key={filter.id} />
            ))}
          </List>
        </li>

        <li>
          <List aria-label="Classification filters">
            <strong aria-hidden="true">Classification: </strong>
            {groupedFilters.classificationSpecific.map((filter) => (
              <Filter filter={filter} key={filter.id} />
            ))}
          </List>
        </li>

        <li>
          <List aria-label="Weight & style filters">
            <strong aria-hidden="true">Weight & style: </strong>
            {groupedFilters.weightsNstyles.map((filter) => (
              <Filter filter={filter} key={filter.id} />
            ))}
          </List>
        </li>

        <li>
          <List aria-label="Operating system filters">
            <strong aria-hidden="true">Operating system:</strong>
            {groupedFilters.operatingSystems.map((filter) => (
              <Filter filter={filter} key={filter.id} />
            ))}
          </List>
        </li>
      </OuterList>

      <div>
        <List aria-label="Selected Filters">
          <strong aria-hidden="true">Selected Filters: </strong>
          {selectedFilters.length > 0 ? (
            <>
              {selectedFilters.length > 1 ? (
                <li>
                  <FilterButton
                    type="button"
                    color="black"
                    bg="var(--accent-soft)"
                    bgHover="var(--accent-soft-hover)"
                    aria-label="Clear all filters"
                    onClick={() => setSelectedFilters([])}
                  >
                    Clear all
                  </FilterButton>
                </li>
              ) : null}

              {selectedFilters.map((filter) => (
                <li key={filter.id}>
                  <FilterButton
                    type="button"
                    bg="var(--accent-soft)"
                    color="black"
                    bgHover="var(--accent-soft-hover)"
                    aria-label={`Remove ${filter.value} filter`}
                    onClick={() => {
                      const newFilters = selectedFilters.filter(
                        (selectedFilter) =>
                          filter.id !== selectedFilter.id
                      );
                      setSelectedFilters(newFilters);
                    }}
                  >
                    {filter.value}
                    <span aria-hidden="true"> ×</span>
                  </FilterButton>
                </li>
              ))}
            </>
          ) : (
            <li>No filters selected.</li>
          )}
        </List>
      </div>

      <p>
        <strong>{activeFonts.length}</strong> fonts match the filters.
      </p>

      <Button
        type="button"
        onClick={() => {
          setFullWidthTable(!fullWidthTable);
        }}
      >
        {fullWidthTable ? "Minimize" : "Expand"} table
      </Button>

      <SkipLink href="#system-fonts-filters">Go to filters</SkipLink>

      <TableWrapper
        tabIndex={0}
        className={fullWidthTable ? "full-width" : null}
      >
        <Table id="system-fonts">
          <thead>
            <tr>
              <th align="left">Name</th>
              <th align="center">Type</th>
              <th align="center">Classification</th>
              <th align="left">Operating&nbsp;System</th>
              <th align="center">Weights & Styles</th>
              <th align="left">Notes</th>
            </tr>
          </thead>
          <tbody>
            {activeFonts.map((font) => (
              <tr key={font.name}>
                <td align="left">{font.name}</td>
                <td align="center">{font.classificationGeneral}</td>
                <td align="center">
                  {font.classificationSpecific.join(", ")}
                </td>
                <td align="left">
                  {font.operatingSystems.join(", ").concat(".")}
                </td>
                <td align="left">{font.weightsNstyles.join(", ")}</td>
                <td align="left">{font.notes}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </div>
  );
};

export default SystemFonts;
