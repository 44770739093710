import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <style type="text/css">
      {`
        /* Reforma1918 */
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 300;
          font-style: normal;
          src: url("/fonts/Reforma1918-Blanca.woff2") format("woff2"),
            url("/fonts/Reforma1918-Blanca.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 300;
          font-style: italic;
          src: url("/fonts/Reforma1918-BlancaItalica.woff2") format("woff2"),
            url("/fonts/Reforma1918-BlancaItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 500;
          font-style: normal;
          src: url("/fonts/Reforma1918-Gris.woff2") format("woff2"),
            url("/fonts/Reforma1918-Gris.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 500;
          font-style: italic;
          src: url("/fonts/Reforma1918-GrisItalica.woff2") format("woff2"),
            url("/fonts/Reforma1918-GrisItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 700;
          font-style: normal;
          src: url("/fonts/Reforma1918-Negra.woff2") format("woff2"),
            url("/fonts/Reforma1918-Negra.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1918";
          font-display: swap;
          font-weight: 700;
          font-style: italic;
          src: url("/fonts/Reforma1918-NegraItalica.woff2") format("woff2"),
            url("/fonts/Reforma1918-NegraItalica.woff") format("woff");
        }
        /* Reforma1969 */
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 300;
          font-style: normal;
          src: url("/fonts/Reforma1969-Blanca.woff2") format("woff2"),
            url("/fonts/Reforma1969-Blanca.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 300;
          font-style: italic;
          src: url("/fonts/Reforma1969-BlancaItalica.woff2") format("woff2"),
            url("/fonts/Reforma1969-BlancaItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 500;
          font-style: normal;
          src: url("/fonts/Reforma1969-Gris.woff2") format("woff2"),
            url("/fonts/Reforma1969-Gris.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 500;
          font-style: italic;
          src: url("/fonts/Reforma1969-GrisItalica.woff2") format("woff2"),
            url("/fonts/Reforma1969-GrisItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 700;
          font-style: normal;
          src: url("/fonts/Reforma1969-Negra.woff2") format("woff2"),
            url("/fonts/Reforma1969-Negra.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma1969";
          font-display: swap;
          font-weight: 700;
          font-style: italic;
          src: url("/fonts/Reforma1969-NegraItalica.woff2") format("woff2"),
            url("/fonts/Reforma1969-NegraItalica.woff") format("woff");
        }
        /* Reforma2018 */
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 300;
          font-style: normal;
          src: url("/fonts/Reforma2018-Blanca.woff2") format("woff2"),
            url("/fonts/Reforma2018-Blanca.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 300;
          font-style: italic;
          src: url("/fonts/Reforma2018-BlancaItalica.woff2") format("woff2"),
            url("/fonts/Reforma2018-BlancaItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 500;
          font-style: normal;
          src: url("/fonts/Reforma2018-Gris.woff2") format("woff2"),
            url("/fonts/Reforma2018-Gris.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 500;
          font-style: italic;
          src: url("/fonts/Reforma2018-GrisItalica.woff2") format("woff2"),
            url("/fonts/Reforma2018-GrisItalica.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 700;
          font-style: normal;
          src: url("/fonts/Reforma2018-Negra.woff2") format("woff2"),
            url("/fonts/Reforma2018-Negra.woff") format("woff");
        }
        @font-face {
          font-family: "Reforma2018";
          font-display: swap;
          font-weight: 700;
          font-style: italic;
          src: url("/fonts/Reforma2018-NegraItalica.woff2") format("woff2"),
            url("/fonts/Reforma2018-NegraItalica.woff") format("woff");
        }
      `}
    </style>
  </Helmet>
);
