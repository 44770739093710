import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Paragraph = styled.p`
  background-color: var(--card);
  border-bottom: 1px dotted var(--muted);

  &:focus {
    outline-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 2px var(--accent);
  }
`;

const SecondSnippet = ({ children }) => {
  return (
    <Paragraph
      suppressContentEditableWarning
      contentEditable
      spellCheck={false}
      onPaste={(e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text");
        document.execCommand("insertText", false, text);
      }}
    >
      {children}
    </Paragraph>
  );
};

SecondSnippet.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SecondSnippet;
