import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  background-color: var(--inline-code);
  display: inline-block;
`;

const Range = styled.span`
  font-family: serif;
  font-size: 1.5em;
  line-height: 1.5;
  font-kerning: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-caps: normal;
  font-feature-settings: "kern";
  background-color: ${({ highlight }) =>
    highlight ? "var(--unicode-highlight)" : "inherit"};
`;

const Character = styled.span`
  display: inline-grid;
  background-color: inherit;
  width: 45px;
  text-align: center;
`;

const HexCode = styled.span`
  font-size: 10px;
  color: var(--color-soft);
`;

const parseRanges = (arr) =>
  arr
    .map(({ range, highlight }) => ({
      range: range.replace(/U\+/g, ""),
      highlight,
    }))
    .reduce((res, { range, highlight }) => {
      if (/-/.test(range)) {
        const [first, last] = range
          .split("-")
          .map((i) => parseInt(i, 16));
        const numbers = [];
        // eslint-disable-next-line no-plusplus
        for (let i = first; i <= last; i++) {
          numbers.push(String.fromCharCode(i));
        }
        return [...res, { range, highlight, characters: numbers }];
      }
      return [
        ...res,
        {
          range,
          highlight,
          characters: [String.fromCharCode(parseInt(range, 16))],
        },
      ];
    }, []);

const RangesToCharacters = ({ unicodeRanges, tagName }) => {
  const ranges = parseRanges(unicodeRanges);

  return (
    <Container as={tagName}>
      {ranges.map(({ range, highlight, characters }) => (
        <Range key={range} highlight={highlight}>
          {characters.map((char) => (
            <Character key={char}>
              <span>{char}</span>
              <HexCode>
                {Number(char.charCodeAt()).toString(16).toUpperCase()}
              </HexCode>
            </Character>
          ))}
        </Range>
      ))}
    </Container>
  );
};

RangesToCharacters.propTypes = {
  unicodeRanges: PropTypes.arrayOf(
    PropTypes.shape({
      range: PropTypes.string.isRequired,
      highlight: PropTypes.bool,
    })
  ).isRequired,
  tagName: PropTypes.string,
};

RangesToCharacters.defaultProps = {
  tagName: "span",
};

export default RangesToCharacters;
