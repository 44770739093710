import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const List = styled.ul`
  display: grid;
  grid-gap: calc(var(--rhythm) / 4);
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  font-family: ${({ fontFamily }) => fontFamily};
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  font-size: 80px;
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  color: var(--color);
  background-color: var(--inline-code);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
`;

const InterestingCharacters = ({ fontFamily, characters }) => (
  <List fontFamily={fontFamily}>
    {characters.map((char) => {
      if (char.italic) {
        return (
          <ListItem key={char} italic>
            {char.char}
          </ListItem>
        );
      }
      return <ListItem key={char}>{char}</ListItem>;
    })}
  </List>
);

InterestingCharacters.propTypes = {
  fontFamily: PropTypes.string.isRequired,
  characters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
};

export default InterestingCharacters;
