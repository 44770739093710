import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Paragraph = styled.p`
  background-color: var(--card);
  border-bottom: 1px dotted var(--muted);

  &:focus {
    outline-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 2px var(--accent);
  }

  overflow: ${({ resizable }) => resizable && "auto"};
  resize: ${({ resizable }) => resizable && "both"};
`;

const FirstSnippet = ({ resizable, children }) => (
  <Paragraph
    suppressContentEditableWarning
    contentEditable
    spellCheck={false}
    resizable={resizable}
  >
    {children}
  </Paragraph>
);

FirstSnippet.propTypes = {
  resizable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FirstSnippet.defaultProps = {
  resizable: false,
};

export default FirstSnippet;
