/**
 * Things to improve:
 * - It's not 100% accessible yet because on TalkBack doesn't
 *   update the dropdown suggestions, it remembers the previous.
 * - I should find a way to share the post/note list data with
 *   the pages. I query them multiple times.
 * - Improve the search. I tried fuse.js but it didn't work well
 *   with large strings like the description. I want a typo
 *   correction search.
 */
import React, { useState } from "react";
import { navigate, useStaticQuery, graphql } from "gatsby";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import orderBy from "lodash.orderby";

import { Box, Heading, Text } from "./Primitives";
import edgeToPost from "../utils/edgeToPost";

const Highlighter = ({ text, query }) => {
  const matches = match(text, query);
  const parts = parse(text, matches);
  return parts.map((part, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Text as={part.highlight ? "mark" : "span"} key={index}>
      {part.text}
    </Text>
  ));
};

const renderSuggestion = (suggestion, { query }) => (
  <Box p={3}>
    <Heading as="h4">
      <Highlighter text={suggestion.title} query={query} />
    </Heading>
    <Text pt={1} fontFamily="system">
      <Highlighter text={suggestion.description} query={query} />
    </Text>
  </Box>
);

const Search = () => {
  const { allMdx } = useStaticQuery(graphql`
    {
      allMdx(
        filter: { frontmatter: { published: { eq: true } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              date(formatString: "DD MMMM, YYYY")
              tags
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const mdx = allMdx.edges.map(edgeToPost);
  const posts = orderBy(mdx, "date");

  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Do nothing.
  const getSuggestionValue = () => search;
  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();

    // Mimic the `match` we use for highlighting to
    // be consistent (for title and description).
    // An alternative is the commented-out line below.
    return posts.filter((post) => {
      const tagMatch = post.tags.includes(inputValue);
      const titleMatch = match(post.title, inputValue).length > 0;
      const descriptionMatch =
        match(post.description, inputValue).length > 0;
      return tagMatch || titleMatch || descriptionMatch;
    });
    // : posts.filter(post => regExp.test(post.title) || regExp.test(post.description));
  };
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) =>
        setSuggestions(getSuggestions(value))
      }
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={(event, { suggestion }) =>
        navigate(suggestion.slug)
      }
      inputProps={{
        "data-test-id": "search-input",
        "aria-label": "Search for a post",
        placeholder: "Search for a post",
        value: search,
        onChange: (event, { newValue }) => setSearch(newValue),
      }}
    />
  );
};

export default Search;
