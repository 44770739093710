import { mixed } from "styled-system";

const black = "#000";
const almostBlack = "#1d1d1d";
const darkGray = "#3f3f37";
const blue = "#4c94c3";
const red = "#EF6F6C";
const darkGreen = "#9fb7af";
const vibrantGreen = "#089c6c";
const emerald = "#4ed2a7";
const green = "#b9e3c6";
const honeydew = "honeydew";
const lightGray = "#f1f5f2";
const white = "#fff";

const lineHeights = {
  normal: 1,
  wide: 1.2,
  wider: 1.5,
  widerest: 1.7,
};
const fonts = {
  fontFamilyUI: "var(--font-family-ui)",
  system: "var(--font-family)",
};
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 82];
const fontWeights = {
  normal: 400,
  semi: 500,
  bold: 700,
};
// Variants common
const common = {
  texts: {
    lineHeight: lineHeights.wider,
  },
  buttons: {
    padding: "calc(var(--rhythm) / 2) var(--rhythm)",
    borderRadius: "calc(var(--rhythm) / 8)",
    fontFamily: fonts.fontFamilyUI,
    fontVariationSettings: "'wght' var(--bold)",
    fontSize: fontSizes[3],
  },
  links: {
    fontSize: fontSizes[3],
    fontWeight: fontWeights.normal,
    lineHeight: lineHeights.normal,
  },
};
export default {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 3.75, 7.5, 15, 30, 60, 120, 240],
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
    hard: "0 0 16px rgba(0, 0, 0, .25)",
  },
  borders: {
    green: `3px solid ${green}`,
  },
  fontSizes,
  fontWeights,
  lineHeights,
  fonts,
  colors: {
    black,
    almostBlack,
    darkGray,
    blue,
    red,
    darkGreen,
    green,
    vibrantGreen,
    emerald,
    honeydew,
    lightGray,
    white,
  },
  // Variants
  headings: {
    h1: mixed({
      lineHeight: lineHeights.wide,
      fontSize: [6, 8],
      fontWeight: fontWeights.bold,
    }),
    h2: {
      lineHeight: lineHeights.normal,
      fontSize: fontSizes[5],
    },
    h3: {
      lineHeight: lineHeights.wide,
      fontFamily: fonts.opensans,
      fontSize: fontSizes[3],
    },
  },
  texts: {
    normal: {
      ...common.texts,
    },
    lead: {
      ...common.texts,
      fontSize: fontSizes[3],
      fontWeight: fontWeights.semi,
    },
    wide: {
      ...common.texts,
      fontSize: fontSizes[4],
    },
  },
  buttons: {
    green: {
      ...common.buttons,
      color: "var(--accent-text)",
      backgroundColor: "var(--accent)",
    },
  },
  links: {
    vibrant: {
      ...common.links,
      color: vibrantGreen,
      lineHeight: 1.5,
      fontFamily: fonts.fontFamilyUI,
      fontVariationSettings: "'wght' 84",
    },
    emerald: {
      ...common.links,
      color: emerald,
      fontWeight: fontWeights.bold,
      lineHeight: lineHeights.wider,
    },
  },
};
