import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Table = styled.table`
  & span {
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 1.5em;
    line-height: 1;
  }
`;
const OpenTypeFeatures = ({ fontFamily }) => (
  <Table fontFamily={fontFamily}>
    <thead>
      <tr>
        <th align="left">Feature code</th>
        <th align="center">Result</th>
        <th align="center">Default</th>
        <th align="left">Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td align="left">
          <code>swsh</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'swsh'" }}>
            A E F L M N R S T
          </span>
        </td>
        <td align="center">
          <span>A E F L M N R S T</span>
        </td>
        <td align="left">Capital swashes</td>
      </tr>
      <tr>
        <td align="left">
          <code>swsh</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'swsh'" }}>n r t y</span>
        </td>
        <td align="center">
          <span>n r t y</span>
        </td>
        <td align="left">Lowercase swashes</td>
      </tr>
      <tr>
        <td align="left">
          <code>cswh</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'cswh'" }}>Reality</span>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'swsh'" }}>Reality</span>
        </td>
        <td align="left">Contextual swashes compared to swashes</td>
      </tr>
      <tr>
        <td align="left">
          <code>dlig</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'dlig'" }}>
            effect station
          </span>
        </td>
        <td align="center">
          <span>effect station</span>
        </td>
        <td align="left">Discretionary ligatures</td>
      </tr>
      <tr>
        <td align="left">
          <code>cswh + dlig</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'dlig', 'cswh'" }}>
            Rocksteady
          </span>
        </td>
        <td align="center">
          <span>Rocksteady</span>
        </td>
        <td align="left">
          Contextual swashes and discretionary ligatures combined
        </td>
      </tr>
      <tr>
        <td align="left">
          <code>ornm</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ornm'" }}>
            • “ ” ‘ ’ ¶
          </span>
        </td>
        <td align="center">
          <span>• “ ” ‘ ’ ¶</span>
        </td>
        <td align="left">Ornaments</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss02</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss02'" }}>l</span>
        </td>
        <td align="center">
          <span>l</span>
        </td>
        <td align="left">Lowercase l without a tail.</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss03</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss03'" }}>R</span>
        </td>
        <td align="center">
          <span>R</span>
        </td>
        <td align="left">Capital R alternate</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss04</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss04'" }}>G</span>
        </td>
        <td align="center">
          <span>G</span>
        </td>
        <td align="left">Capital G alternate</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss06</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss06'" }}>a</span>
        </td>
        <td align="center">
          <span>a</span>
        </td>
        <td align="left">Single story lowercase a</td>
      </tr>
    </tbody>
  </Table>
);

OpenTypeFeatures.propTypes = {
  fontFamily: PropTypes.string.isRequired,
};

export default OpenTypeFeatures;
