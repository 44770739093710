// I want to use graphql inside an MDX file and, apparently, this doesn't
// work: https://markoskon/graphql-queries-inside-mdx/. It works only with
// a custom hook.
/* eslint-disable camelcase */
import { graphql, useStaticQuery } from "gatsby";

export const useTranslation = () => {
  const data = useStaticQuery(graphql`
    query translation {
      allTranslationsJson {
        nodes {
          hello_world
        }
      }
    }
  `);
  const {
    allTranslationsJson: { nodes },
  } = data;
  const { hello_world } = nodes[0];
  return hello_world;
};
