import React, { useState } from "react";
import styled from "styled-components";

import TableWrapper from "../../../src/components/TableWrapper";

const Container = styled.div`
  & span {
    font-family: ${({ fontFamily }) => fontFamily};
    font-weight: ${({ fontWeight }) => fontWeight};
    font-size: 1.5em;
    line-height: 1;
  }
`;

const LabelContainer = styled.div`
  display: grid;
  grid-column-gap: var(--rhythm);
  grid-row-gap: calc(var(--rhythm) / 4);
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  margin: 0 0 var(--rhythm);

  label {
    display: flex;
    align-items: center;
  }

  select {
    margin-left: auto;
    background-color: var(--input-bg);
    color: var(--color);
    border-color: var(--input-border);
  }
`;

const ReformaFeatures = () => {
  const [fontFamily, setFontFamily] = useState("Reforma1918");
  const [fontWeight, setFontWeight] = useState("500");
  return (
    <Container fontFamily={fontFamily} fontWeight={fontWeight}>
      <LabelContainer>
        <label htmlFor="sub-family">
          <strong>Sub-family:</strong>{" "}
          <select
            id="sub-family"
            name="sub-family"
            value={fontFamily}
            onChange={(e) => setFontFamily(e.target.value)}
          >
            <option value="Reforma1918">Reforma 1918</option>
            <option value="Reforma1969">Reforma 1969</option>
            <option value="Reforma2018">Reforma 2018</option>
          </select>
        </label>
        <label htmlFor="weight">
          <strong>Weight:</strong>{" "}
          <select
            id="weight"
            name="weight"
            value={fontWeight}
            onChange={(e) => setFontWeight(e.target.value)}
          >
            <option value="300">Blanca 300</option>
            <option value="500">Gris 500</option>
            <option value="700">Negra 700</option>
          </select>
        </label>
      </LabelContainer>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              <th align="left">Feature code</th>
              <th align="center">On</th>
              <th align="center">Off</th>
              <th align="left">Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">
                <code>salt or ss01</code>
              </td>
              <td align="center">
                <span style={{ fontFeatureSettings: "'salt'" }}>
                  J K R Q
                </span>
              </td>
              <td align="center">
                <span>J K R Q</span>
              </td>
              <td align="left">All stylistic alternates.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss02</code>
              </td>
              <td align="center">
                <span style={{ fontFeatureSettings: "'ss02'" }}>
                  Jupiter
                </span>
              </td>
              <td align="center">
                <span>Jupiter</span>
              </td>
              <td align="left">Capital J without a descender.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss03</code>
              </td>
              <td align="center">
                <span style={{ fontFeatureSettings: "'ss03'" }}>
                  ROCK
                </span>
              </td>
              <td align="center">
                <span>ROCK</span>
              </td>
              <td align="left">Long leg in capital K & R.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss04</code>
              </td>
              <td align="center">
                <span style={{ fontFeatureSettings: "'ss04'" }}>
                  Quality
                </span>
              </td>
              <td align="center">
                <span>Quality</span>
              </td>
              <td align="left">Capital Q with a long tail.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss05 (italic)</code>
              </td>
              <td align="center">
                <span
                  style={{
                    fontFeatureSettings: "'ss05'",
                    fontStyle: "italic",
                  }}
                >
                  Quality
                </span>
              </td>
              <td align="center">
                <span style={{ fontStyle: "italic" }}>Quality</span>
              </td>
              <td align="left">
                Italic capital Q with an extra-long tail.
              </td>
            </tr>
            <tr>
              <td align="left">
                <code>ss06</code>
              </td>
              <td align="center">
                <span style={{ fontFeatureSettings: "'ss06'" }}>
                  Tome
                </span>
              </td>
              <td align="center">
                <span>Tome</span>
              </td>
              <td align="left">Taller capital T.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss07 (italic)</code>
              </td>
              <td align="center">
                <span
                  style={{
                    fontFeatureSettings: "'ss07'",
                    fontStyle: "italic",
                  }}
                >
                  Ysera
                </span>
              </td>
              <td align="center">
                <span style={{ fontStyle: "italic" }}>Ysera</span>
              </td>
              <td align="left">Alternate italic capital Y.</td>
            </tr>
            <tr>
              <td align="left">
                <code>dlig (italic)</code>
              </td>
              <td align="center">
                <span
                  style={{
                    fontFeatureSettings: "'dlig'",
                    fontStyle: "italic",
                  }}
                >
                  effect station
                </span>
              </td>
              <td align="center">
                <span style={{ fontStyle: "italic" }}>
                  effect station
                </span>
              </td>
              <td align="left">Italic discretionary ligatures.</td>
            </tr>
            <tr>
              <td align="left">
                <code>ss05 + dlig + italic</code>
              </td>
              <td align="center">
                <span
                  style={{
                    fontFeatureSettings: "'ss05', 'dlig'",
                    fontStyle: "italic",
                  }}
                >
                  Question
                </span>
              </td>
              <td align="center">
                <span style={{ fontStyle: "italic" }}>Question</span>
              </td>
              <td align="left">Combination.</td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </Container>
  );
};

export default ReformaFeatures;
