import React from "react";
import styled from "styled-components";
import { string, node } from "prop-types";

const Details = styled.details`
  summary {
    margin-bottom: var(--rhythm);
  }

  & > :not(:first-child) {
    padding-left: 1em;
  }
`;

const Disclosure = ({ title, children }) => {
  return (
    <Details>
      <summary>{title}</summary>
      {children}
    </Details>
  );
};

Disclosure.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};

export default Disclosure;
