import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ControlContainer = styled.div`
  display: grid;
  grid-column-gap: var(--rhythm);
  grid-row-gap: calc(var(--rhythm) / 4);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0 0 var(--rhythm);

  strong,
  input {
    margin-right: calc(var(--rhythm) / 4);
  }

  label,
  input,
  select {
    line-height: 1.5;
  }

  strong {
    width: 60%;
  }

  input,
  select {
    margin-left: auto;
    width: 40%;
    background-color: var(--input-bg);
    color: var(--color);
    border-color: var(--input-border);
  }

  label {
    display: flex;
    align-items: center;
  }
`;

/**
 * Using weird prop names like `fFamily` instead of
 * `fontFamily`, because the last is treated as
 * an inline style.
 */
const Editable = styled.p`
  font-family: ${({ fFamily }) => fFamily};
  font-weight: ${({ fWeight }) => fWeight};
  font-style: ${({ fStyle }) => fStyle};
  font-stretch: ${({ fWidth }) => fWidth};
  font-size: ${({ fSize }) => `${fSize}px`};
  line-height: ${({ lHeight }) => lHeight};
  letter-spacing: ${({ lSpacing }) => `${lSpacing}em`};
  resize: both;
  overflow: auto;
  background-color: var(--card);
  color: var(--text);
  border-top: 1px solid #ccc;
  padding: calc(var(--rhythm) / 2) calc(var(--rhythm) / 8);

  &:focus {
    outline: 2px solid transparent;
    box-shadow: 0 0 0 2px var(--accent);
    border-top: 1px solid transparent;
  }
`;

Editable.propTypes = {
  fFamily: PropTypes.string.isRequired,
  fWeight: PropTypes.string.isRequired,
  fStyle: PropTypes.string.isRequired,
  fWidth: PropTypes.string.isRequired,
  fSize: PropTypes.number.isRequired,
  lHeight: PropTypes.number.isRequired,
  lSpacing: PropTypes.number.isRequired,
};

const Options = memo(({ options }) =>
  options.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ))
);

Options.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { ControlContainer, Editable, Options };
