const fields = [
  "classificationGeneral",
  "classificationSpecific",
  "weightsNstyles",
  "operatingSystems",
];

const getUnique = (array) => Array.from(new Set(array));

export const getUniqueByField = (field) => (array) =>
  getUnique(
    array.reduce((result, obj) => result.concat(obj[field]), [])
  );

export const generateFilters = (fonts) =>
  fields.reduce((result, field) => {
    const fieldUniqueValues = getUniqueByField(field)(fonts);

    const filters = fieldUniqueValues.map((value) => {
      const operation =
        field === "classificationGeneral" ? "EQUALS" : "INCLUDES";
      const id = `${field} ${operation} ${value}`;
      return {
        field,
        operation,
        value,
        id,
      };
    });

    return result.concat(filters);
  }, []);
