import React, { useState } from "react";
import PropTypes from "prop-types";

import { Box } from "../Primitives";
import { ControlContainer, Editable, Options } from "./Components";

const FontPlayground = ({
  subFamilies,
  fontFamily,
  weights,
  styles,
  widths,
}) => {
  const [subFamily, setSubFamily] = useState(
    subFamilies && subFamilies[0]
  );

  const [fontWeight, setFontWeight] = useState("400");
  const [fontStyle, setFontStyle] = useState("normal");
  const [fontWidth, setFontWidth] = useState("normal");

  const [fontSize, setFontSize] = useState(20);
  const [lineHeight, setLineHeight] = useState(1.5);
  const [letterSpacing, setLetterSpacing] = useState(0);
  return (
    <Box>
      <ControlContainer>
        {subFamilies && (
          <label htmlFor="select-sub-family">
            <strong>Sub-family: </strong>
            <select
              id="select-sub-family"
              name="select-sub-family"
              value={subFamily}
              onChange={(e) => {
                setSubFamily(e.target.value);
              }}
            >
              <Options options={subFamilies} />
            </select>
          </label>
        )}
        <label htmlFor="select-weight">
          <strong>Weight: </strong>
          <select
            id="select-weight"
            name="select-weight"
            value={fontWeight}
            onChange={(e) => {
              setFontWeight(e.target.value);
            }}
          >
            <Options options={weights} />
          </select>
        </label>
        <label htmlFor="select-style">
          <strong>Style: </strong>
          <select
            id="select-style"
            name="select-style"
            value={fontStyle}
            onChange={(e) => {
              setFontStyle(e.target.value);
            }}
          >
            <Options options={styles} />
          </select>
        </label>
        <label htmlFor="select-width">
          <strong>Width: </strong>
          <select
            id="select-width"
            name="select-width"
            value={fontWidth}
            onChange={(e) => {
              setFontWidth(e.target.value);
            }}
          >
            <Options options={widths} />
          </select>
        </label>
        <label htmlFor="input-size">
          <strong>Size: </strong>
          <input
            id="input-size"
            name="input-size"
            type="range"
            min={8}
            max={150}
            value={fontSize}
            onChange={(e) => {
              setFontSize(Number(e.target.value));
            }}
          />
          <span style={{ minWidth: "54px", textAlign: "right" }}>
            {`${fontSize}px`}
          </span>
        </label>
        <label htmlFor="input-line-height">
          <strong>Line height: </strong>
          <input
            id="input-line-height"
            name="input-line-height"
            type="range"
            min={1}
            max={2}
            step={0.05}
            value={lineHeight}
            onChange={(e) => {
              setLineHeight(Number(e.target.value));
            }}
          />
          <span>{`${lineHeight.toFixed(2)}em`}</span>
        </label>
        <label htmlFor="input-letter-spacing">
          <strong>Letter spacing: </strong>
          <input
            id="input-letter-spacing"
            name="input-letter-spacing"
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={letterSpacing}
            onChange={(e) => {
              setLetterSpacing(Number(e.target.value));
            }}
          />
          <span>{`${letterSpacing.toFixed(2)}em`}</span>
        </label>
      </ControlContainer>

      <Editable
        suppressContentEditableWarning
        contentEditable
        spellCheck={false}
        aria-label="Sample text"
        fFamily={subFamily || fontFamily}
        fWeight={fontWeight}
        fStyle={fontStyle}
        fWidth={fontWidth}
        fSize={fontSize}
        lHeight={lineHeight}
        lSpacing={letterSpacing}
        onPaste={(e) => {
          e.preventDefault();
          const onlyText = e.clipboardData.getData("text");
          document.execCommand("insertText", false, onlyText);
        }}
      >
        A peep at some distant orb has power to raise and purify our
        thoughts like a strain of sacred music, or a noble picture, or a
        passage from the grander poets. It always does one good.
      </Editable>
    </Box>
  );
};

FontPlayground.propTypes = {
  subFamilies: PropTypes.arrayOf(PropTypes.string),
  fontFamily: PropTypes.string,
  weights: PropTypes.arrayOf(PropTypes.string),
  styles: PropTypes.arrayOf(PropTypes.string),
  widths: PropTypes.arrayOf(PropTypes.string),
};

FontPlayground.defaultProps = {
  subFamilies: null,
  fontFamily: null,
  weights: ["400"],
  styles: ["normal"],
  widths: ["normal"],
};

export default FontPlayground;
