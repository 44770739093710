import React from "react";
import styled from "styled-components";
import { string } from "prop-types";

const Editable = styled.p`
  font-family: ${({ family }) => family};
  line-height: normal;
  resize: both;
  overflow: auto;
  background-color: var(--card);
  color: var(--text);
  outline: 2px solid transparent;
  padding: calc(var(--rhythm) / 2) calc(var(--rhythm) / 8);

  &:focus {
    box-shadow: 0 0 0 2px var(--accent);
  }
`;

Editable.propTypes = {
  family: string,
};

Editable.defaultProps = {
  family: "inherit",
};

const ContentEditable = ({ family }) => (
  <Editable
    family={family}
    suppressContentEditableWarning
    contentEditable
    spellCheck={false}
    aria-label="Sample text"
    onPaste={(e) => {
      e.preventDefault();
      const onlyText = e.clipboardData.getData("text");
      document.execCommand("insertText", false, onlyText);
    }}
  >
    A peep at some distant orb has power to raise and purify our thoughts
    like a strain of sacred music, or a noble picture, or a passage from
    the grander poets. It always does one good.
  </Editable>
);

ContentEditable.propTypes = Editable.propTypes;
ContentEditable.defaultProps = Editable.defaultProps;

export default ContentEditable;
