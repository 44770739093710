const edgeToPost = ({ node }) => ({
  slug: node.fields.slug,
  title: node.frontmatter.title,
  description: node.frontmatter.description,
  date: node.frontmatter.date,
  dateSort: node.frontmatter.dateSort,
  tags: node.frontmatter.tags,
  timeToRead: node.timeToRead,
});

export default edgeToPost;
