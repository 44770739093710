import React from "react";
import { node, string } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: var(--rhythm);

  &:focus {
    outline: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--muted);
  }
`;

const TableWrapper = ({ children, className }) => (
  <Wrapper tabIndex={0} className={className}>
    {children}
  </Wrapper>
);

TableWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

TableWrapper.defaultProps = {
  className: null,
};

export default TableWrapper;
