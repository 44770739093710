import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <style type="text/css">
      {`
        /* Roman */
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-latin.woff2") format("woff2");
          unicode-range: U+00-FF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-rest.woff2") format("woff2");
          unicode-range: U+2000-1F916;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-greek.woff2") format("woff2");
          unicode-range: U+0370-03FF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-greek-extended.woff2")
            format("woff2");
          unicode-range: U+1F00-1FFF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-combining-diacritical-marks.woff2")
            format("woff2");
          unicode-range: U+0300-036F;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: normal;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Roman-phonetic.woff2") format("woff2");
          unicode-range: U+0250-02FF;
        }
        /* Italic */
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-latin.woff2") format("woff2");
          unicode-range: U+00-FF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-rest.woff2") format("woff2");
          unicode-range: U+2000-1F916;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-greek.woff2") format("woff2");
          unicode-range: U+0370-03FF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-greek-extended.woff2")
            format("woff2");
          unicode-range: U+1F00-1FFF;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-combining-diacritical-marks.woff2")
            format("woff2");
          unicode-range: U+0300-036F;
        }
        @font-face {
          font-family: "Source Sans Variable";
          font-display: swap;
          font-weight: 200 900;
          font-style: italic;
          font-stretch: normal;
          src: url("/fonts/SourceSansVariable-Italic-phonetic.woff2")
            format("woff2");
          unicode-range: U+0250-02FF;
        }
      `}
    </style>
  </Helmet>
);
