import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <style type="text/css">
      {`
        /* Roman */
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: normal;
          src: url("/fonts/WorkSans-Roman-VF-no-hint-latin.woff2") format("woff2");
          unicode-range: U+00-FF;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: normal;
          src: url("/fonts/WorkSans-Roman-VF-no-hint-latin-extended-a.woff2")
            format("woff2");
          unicode-range: U+0100-017F;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: normal;
          src: url("/fonts/WorkSans-Roman-VF-no-hint-latin-extended-additional.woff2")
            format("woff2");
          unicode-range: U+1E00-1EFF;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: normal;
          src: url("/fonts/WorkSans-Roman-VF-no-hint-rest.woff2") format("woff2");
          unicode-range: U+2000-FB02;
        }
        /* Italic */
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: italic;
          src: url("/fonts/WorkSans-Italic-VF-no-hint-latin.woff2") format("woff2");
          unicode-range: U+00-FF;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: italic;
          src: url("/fonts/WorkSans-Italic-VF-no-hint-latin-extended-a.woff2")
            format("woff2");
          unicode-range: U+0100-017F;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: italic;
          src: url("/fonts/WorkSans-Italic-VF-no-hint-latin-extended-additional.woff2")
            format("woff2");
          unicode-range: U+1E00-1EFF;
        }
        @font-face {
          font-family: "Work Sans Variable";
          font-display: swap;
          font-weight: 100 900;
          font-stretch: normal;
          font-style: italic;
          src: url("/fonts/WorkSans-Italic-VF-no-hint-rest.woff2") format("woff2");
          unicode-range: U+2000-FB02;
        }
        `}
    </style>
  </Helmet>
);
