import React from "react";

// eslint-disable-next-line react/prop-types
const PreserveExample = ({ style, children }) => (
  <div
    style={{
      padding: "8px",
      backgroundColor: "#1d1d1d",
      color: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "5px",
      ...style,
    }}
  >
    <p>{children}</p>
  </div>
);

export default PreserveExample;
