import React from "react";

const LongLinkExample2 = () => (
  <div
    style={{
      backgroundColor: "#1d1d1d",
      padding: "8px",
      width: "300px",
    }}
  >
    <a
      href="https://github.com/facebook/react/issues/14920#issuecomment-471070149"
      style={{ color: "#f18fe8", wordBreak: "break-word" }}
      target="noopener noreferrer"
    >
      https://github.com/facebook/react/issues/14920#issuecomment-471070149
    </a>
  </div>
);

export default LongLinkExample2;
