import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Navbar as BaseNavbar } from "accessible-navbar";

import Link from "../Link";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import {
  SiteHeading,
  CustomDesktopList,
  CustomMobileList,
  BrandContainer,
} from "./StyledComponents";

const Navbar = ({ currentPath }) => {
  const { title, logo } = useSiteMetadata();
  return (
    <BaseNavbar
      applicationNodeId="___gatsby"
      bc="var(--bg-dark)"
      c="var(--color)"
      hc="var(--accent)"
      topEffect={false}
      brand={
        <BrandContainer>
          <div aria-hidden="true">
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              alt=""
            />
          </div>
          <Link hc="var(--accent)" to="/" data-test-id="logo">
            {currentPath === "/" ? (
              <SiteHeading>{title}</SiteHeading>
            ) : (
              title
            )}
          </Link>
        </BrandContainer>
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      desktopList={(props) => <CustomDesktopList {...props} />}
      // eslint-disable-next-line react/no-unstable-nested-components
      mobileList={({ hideMobile, children, ...props }) => (
        <CustomMobileList hideMobile={hideMobile} {...props}>
          <Link
            py={2}
            px={3}
            to="/"
            activeClassName="active"
            activeStyle={{ color: "var(--accent)" }}
            onClick={() => hideMobile()}
          >
            Blog
          </Link>
          <Link
            py={2}
            px={3}
            to="/about/"
            activeClassName="active"
            activeStyle={{ color: "var(--accent)" }}
            onClick={() => hideMobile()}
          >
            About
          </Link>
          <Link
            py={2}
            px={3}
            to="/contact/"
            activeClassName="active"
            activeStyle={{ color: "var(--accent)" }}
            onClick={() => hideMobile()}
          >
            Contact
          </Link>
        </CustomMobileList>
      )}
    >
      <Link
        py={2}
        px={3}
        to="/"
        activeClassName="active"
        activeStyle={{ color: "var(--accent)" }}
      >
        Blog
      </Link>
      <Link
        py={2}
        px={3}
        to="/about/"
        activeClassName="active"
        activeStyle={{ color: "var(--accent)" }}
      >
        About
      </Link>
      <Link
        py={2}
        px={3}
        to="/contact/"
        activeClassName="active"
        activeStyle={{ color: "var(--accent)" }}
      >
        Contact
      </Link>
    </BaseNavbar>
  );
};

Navbar.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default Navbar;
