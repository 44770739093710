/* eslint-disable react/jsx-filename-extension, react/prop-types */
import React from "react";
import { ThemeProvider } from "styled-components";
import { MDXProvider } from "@mdx-js/react";

import theme from "../layouts/theme";
import components from "../components/MDXComponents";

/** @type {import('gatsby').GatsbySSR['wrapRootElement']} */
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </MDXProvider>
);
