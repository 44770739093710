/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Toggle from "react-toggle";

import { Flex } from "./Primitives";

const StyledToggle = styled(Toggle)`
  margin-left: calc(var(--rhythm) / 4);
`;

const DarkModeLabel = styled(Flex)`
  font-family: var(--font-family-ui);
  font-weight: 400;
  font-variation-settings: "wght" var(--regular);
  color: var(--color);
  margin-right: 1.5%;
  position: absolute;
  top: 20px;
  right: 0;
`;

const ThemeSwitcher = () => {
  const [theme, setTheme] = useState(null);
  useEffect(() => {
    setTheme(window.__theme);
    const callback = () => setTheme(window.__theme);
    window.__addCallback(callback);
    return () => {
      window.__removeCallback(callback);
    };
  }, []);
  return theme ? (
    <DarkModeLabel
      as="label"
      alignItems="center"
      p={2}
      data-test-id="dark-mode-switch"
    >
      Dark Mode:
      <StyledToggle
        icons={false}
        checked={theme === "dark"}
        onChange={(e) =>
          window.__setTheme(e.target.checked ? "dark" : "light")
        }
      />
    </DarkModeLabel>
  ) : (
    <div
      style={{
        // height: '49px',
        position: "absolute",
      }}
    />
  );
};

export default ThemeSwitcher;
