import PropTypes from "prop-types";
import styled from "styled-components";
import {
  color,
  fontSize,
  fontFamily,
  fontWeight,
  lineHeight,
  variant,
  space,
} from "styled-system";

const linkStyle = variant({
  key: "links",
});

const LinkExternal = styled.a`
  ${linkStyle}
  ${color}
  ${space}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}

  display: inline-block;
  text-decoration: none;
  transition: 0.3s color ease-out;

  &:hover {
    color: ${({ hc }) => hc};
  }
`;

LinkExternal.propTypes = {
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  hc: PropTypes.string,
};

LinkExternal.defaultProps = {
  hc: null,
  variant: "emerald",
};

export default LinkExternal;
