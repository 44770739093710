/* eslint-disable react/jsx-filename-extension, react/prop-types */
import React from "react";

import Layout from "../components/Layout";

/** @type {import('gatsby').GatsbySSR['wrapPageElement']} */
export const wrapPageElement = ({
  element,
  props: {
    location: { pathname },
  },
}) => <Layout path={pathname}>{element}</Layout>;
