/* eslint-disable no-underscore-dangle */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Footer from "./Footer";
import { Box } from "./Primitives";
import Search from "./Search";
import SkipLink from "./SkipLink";
import Navbar from "./Navbar";
import ThemeSwitcher from "./ThemeSwitcher";

const Content = styled.main`
  margin: 0 auto;
  max-width: calc(var(--rhythm) * 24);
  min-height: 70vh;
  hyphens: manual;
  padding: var(--rhythm) 0 calc(var(--rhythm) * 2);
  @media screen and (max-width: 650px) {
    position: relative;
    top: 50px;
  }
  @media screen and (max-width: 576px) {
    padding: var(--rhythm) 0.2em calc(var(--rhythm) * 3);
  }

  ul,
  li {
    margin-left: calc(var(--rhythm) / 4);
  }
`;

const Layout = ({ children, path }) => {
  return (
    <Fragment>
      <Box id="top" height={0} aria-hidden="true" />
      <SkipLink href="#main">Skip to main content</SkipLink>
      <header>
        <Navbar currentPath={path} />
        <Box pt="var(--rhythm)" style={{ position: "relative" }}>
          <ThemeSwitcher />
          <Search />
        </Box>
      </header>
      <Content id="main" role="main">
        {children}
      </Content>
      <Footer />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default Layout;
