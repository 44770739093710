import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Set`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Range`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Latin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0000-00FF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Latin Extended-A`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0100-017F`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Latin Extended-B`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0180-024F`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`probably below 50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><abbr title="International Phonetic Alphabet">{`IPA`}</abbr>{` Extensions`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0250-02AF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Spacing Modifier Letters`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+02B0-02FF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Combing Diacritical Marks`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0300-036F`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Greek`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0370-03FF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cyrillic`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+0400-04FF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Latin Extended Additional`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+1E00-1EFF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Greek Extended`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+1F00-1FFF`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`U+2000-1F916`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`234 chars!`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      